import React from "react";
import logoBZ from '../../static/img/biozyme.png';
import logoAM from '../../static/img/additive.png';

const Header = () => {
    return (
        <header className="header">
            <img src={logoBZ} className="header__logo" alt="BioZyme logo" />
            <img src={logoAM} className="header__logo" alt="Additive logo" />
        </header>
    );
}

export default Header;
