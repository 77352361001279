import React from "react";
import icon32 from "../../static/img/icon-32x32.png";
import icon128 from "../../static/img/icon-128x128.png";
import icon144 from "../../static/img/icon-144x144.png";
import icon152 from "../../static/img/icon-152x152.png";
import icon192 from "../../static/img/icon-192x192.png";
import icon256 from "../../static/img/icon-256x256.png";
import icon384 from "../../static/img/icon-384x384.png";
import icon512 from "../../static/img/icon-512x512.png";

const Loader = ({showState}) => {
    return (
        <div className={showState}>
            <h2>Loading, please wait.</h2>
            <div id="loader">
                <svg viewBox="0 0 32 32" width="32" height="32">
                    <circle id="spinner" cx="16" cy="16" r="14" fill="none" />
                </svg>
            </div>
            <div className="hidden" hidden aria-hidden="true">
                <img src={icon32} alt="touch icon 32x32" />
                <img src={icon128} alt="touch icon 128x128" />
                <img src={icon144} alt="touch icon 144x144" />
                <img src={icon152} alt="touch icon 152x152" />
                <img src={icon192} alt="touch icon 192x192" />
                <img src={icon256} alt="touch icon 256x256" />
                <img src={icon384} alt="touch icon 384x384" />
                <img src={icon512} alt="touch icon 512x512" />
            </div>
        </div>
    )
}

export default Loader;