import React from "react";
import logoFC from  '../../static/img/img_full-circle.png';

const Footer = () => {
    return (
        <footer>
            <img
                src={logoFC}
                className="footer-quote"
                alt="Care that comes full circle"
            />
            <p>
                Copyright © 1965-<span id="curr-year">2019</span> BioZyme,&nbsp;Inc.
          </p>
            <p>
                All trademarks and other intellectual property are owned by
                BioZyme,&nbsp;Inc., St.&nbsp;Joseph, Missouri,&nbsp;USA
          </p>
            <p className="footer-links">
                <a
                    href="https://biozymeinc.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Privacy&nbsp;Policy
            </a>
                &nbsp;|&nbsp;&nbsp;
            <a
                    href="https://biozymeinc.com/terms-conditions/"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Terms&nbsp;&amp;&nbsp;Conditions
            </a>
                &nbsp;|&nbsp;&nbsp;
            <a
                    href="https://biozymeinc.com/contact-us/"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Contact&nbsp;Us
            </a>
            </p>
        </footer>
    )
}

export default Footer;