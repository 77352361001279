const firstUpper = (lower) => {
  return lower.replace(/^\w/, c => c.toUpperCase());
}

const setNumeric = (stringNumber) => {

  if (!isNaN(parseFloat(stringNumber))) {
    
    return parseFloat(stringNumber);
  } 

  if (stringNumber === '.') {
    return '.';
  }


}

export { firstUpper, setNumeric };

