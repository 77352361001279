import React, { useState } from 'react';
import Header from "./components/layout/header";
import Loader from "./components/layout/loader";
import Footer from "./components/layout/footer";
import logoAmaferm from "./static/img/logo_amaferm.png";

import SettingsView from "./components/form/settings-view";

import { setNumeric } from "./utilities/strings";

import './static/scss/App.scss';

import productData from './data/pricing.json';
import optionData from './data/options.json';
import dosingData from './data/dosing.json';
const App = () => {

  const convertCost = (cost, measurement) => {
    if (measurement === 'metric') {
      return cost * 2.20462;
    }

    if (measurement === 'imperial') {
      return cost * 0.453592;
    }

    return cost;
  }

  const convertWeight = (weight, measurement) => {
    if (measurement === 'metric') {
      let result = weight * 0.453592;
      return result.toFixed(3);
    }

    if (measurement === 'imperial') {
      let result = weight * 2.20462;
      return result.toFixed(3);
    }

    return weight;
  }

  // loading data from json files (need to switch to axios calls)
  const [products] = useState(productData);
  const [options] = useState(optionData);
  const [doses] = useState(dosingData);

  // state affected through settings page
  const [measurement, setMeasurement] = useState('imperial');
  const [purchaseSource, setPurchaseSource] = useState('direct')

  // main form state 

  const [calcView, setCalcView] = useState('body');
  const [settingsView, setSettingsView] = useState('hidden');
  const [resultsView, setResultsView] = useState('hidden');

  const [additive, setAdditive] = useState(products[0]);

  const [additiveGroup, setAdditiveGroup] = useState(products.filter((product) =>
    product.compare === additive.compare));

  const [priceDirect, setPriceDirect] = useState(additive['price']['direct']);
  const [pricePartner, setPricePartner] = useState(additive['price']['partner']);

  const [species, setSpecies] = useState('empty');
  const [initialFeedingRate, setInitialFeedingRate] = useState(0);

  const [phase, setPhase] = useState('empty');

  const [feedingRate, setFeedingRate] = useState(0);
  const [feedIntake, setFeedIntake] = useState(0);

  // Get production phase options

  let additiveStages = additiveGroup.map((product, index) => {

    let stages = [];

    if (species !== 'empty') {

      let phaseNames = options.filter((option, i) => option.id === species);

      let doseOptions = doses.filter((dose, i) =>
        dose.species === species && dose.id.split('-')[0] === product.id);

      let phases = phaseNames[0].phases.map((phase) => {

        let stageData;
        doseOptions.map((dose, i) => {
          if (dose.phase === phase.id) {
            stageData = dose;
          }
        })

        if (stageData) {
          stages.push({ ...stageData, name: phase.name })
        }

      });

    }
    return stages;


  });


  const resetForm = () => {
    setSpecies('empty');
    setPhase('empty');
    setInitialFeedingRate(0);
    setFeedingRate(0);
    setFeedIntake(0);
  }

  const openSettings = (e) => {
    setCalcView('hidden');
    setSettingsView('body');
  }

  const closeSettings = (e) => {
    setSettingsView('hidden');
    setCalcView('body');
  }

  const openResults = (e) => {
    setCalcView('hidden');
    setResultsView('body');
  }

  const closeResults = (e) => {
    setResultsView('hidden');
    setCalcView('body');
  }

  // settings screen functions

  const measurementUnitChange = (e) => {
    let measurement = e.target.value;
    setMeasurement(measurement);
    setFeedIntake(convertWeight(feedIntake, measurement));
    additiveGroupPriceConversion(measurement);
  }

  const purchaseSourceChange = (e) => {
    setPurchaseSource(e.target.value);
  }

  // form functions

  const additiveChange = (e) => {
    resetForm();

    let productSearch = products.filter((product) => {
      return product.id === e.target.value;
    });

    setAdditive(productSearch[0])

    setAdditiveGroup(products.filter((product) =>
      product.compare === productSearch[0].compare))

    setPriceDirect(productSearch[0].price.direct)
    setPricePartner(productSearch[0].price.partner)
  }

  const speciesChange = (e) => {
    resetForm();
    setSpecies(e.target.value);
  }


  const phaseChange = (e) => {
    let phaseID = e.target.value.split(' ')[0];
    let phaseValue = e.target.value.split(' ')[1];
    setPhase(phaseID);
    setInitialFeedingRate(parseFloat(phaseValue));
    setFeedingRate(parseFloat(phaseValue));
  }

  const feedingRateChange = (e) => {
    let feedingRate = e.target.value;
    setFeedingRate(setNumeric(feedingRate));
  }

  const feedIntakeChange = (e) => {
    let feedIntake = e.target.value;
    setFeedIntake(setNumeric(feedIntake));
  }

  const additiveGroupPriceChange = (e) => {
    let changedID = e.target.name.split('-')[0];
    let changedSource = e.target.name.split('-')[1];
    let changedPrice = e.target.value;

    setAdditiveGroup(additiveGroup.map((product, index) => {
      if (product.id === changedID) {

        let prices = {
          price: {
            direct: product.price.direct,
            partner: product.price.partner
          }
        };


        if (changedSource === 'direct') {
          prices.price.direct = changedPrice;
          if (additive.id === product.id) {
            setPriceDirect(changedPrice);
          }

        } else {
          prices.price.partner = changedPrice;
          if (additive.id === product.id) {
            setPricePartner(changedPrice);
          }
        }

        return { ...product, ...prices }
      }
      return product
    }))
  }

  const additiveGroupPriceConversion = (units) => {

    setAdditiveGroup(additiveGroup.map((product, index) => {
      let prices = {
        price: {
          direct: product.price.direct,
          partner: product.price.partner
        }
      };

      let direct = parseFloat(prices.price.direct);
      let partner = parseFloat(prices.price.partner);



      prices.price.direct = convertCost(direct, units).toFixed(2);
      prices.price.partner = convertCost(partner, units).toFixed(2);

      if (additive.id === product.id) {
        setPriceDirect(prices.price.direct);
        setPricePartner(prices.price.partner);
      }

      return { ...product, ...prices }
    }));

  }

  const getProductRate = (productID) => {
    if (species !== 'empty' && phase !== 'empty') {

      let selectedStages = additiveStages.filter((stage) => {
        return stage[0].additive === productID;
      })

      let selectedStage = selectedStages[0].filter((stage) => {
        return stage.phase === phase;
      });

      return selectedStage[0].dose;

    }

    return 0;

  }

  // result card calculations

  const calculateWeightPerTon = (units, rate, intake) => {
    if (feedingRate && feedIntake) {
      rate = parseFloat(rate);
      intake = parseFloat(intake);

      if (units === 'imperial') {
        return (((rate / intake) * 2000) / 454).toFixed(2);
      }

      if (units === 'metric') {
        return (((rate / intake) * 1000) / 1000).toFixed(2);
      }
    }

    return 0;

  }


  const calculatePricePerTon = (weight, price) => {
    price = parseFloat(price);
    return (price * weight).toFixed(2);
  }

  const calculatePricePerHead = (units, price, rate) => {
    rate = parseFloat(rate);
    price = parseFloat(price);

    if (units === 'imperial') {
      return ((price / 454) * rate).toFixed(3);
    }

    if (units === 'metric') {
      return ((price / 1000) * rate).toFixed(3);
    }
  }



  return (
    <>
      <Header />
      <main id="main">
        <>
          <form>
            <>
              <div className={calcView}>
                <span id="btnSettingsOpen" className="settings-on" onClick={openSettings}>
                  <span className="sr-ony">Open Settings</span>
                </span>
                <h1 className="title">Additive Inclusion Calculator</h1>
                <div className="calc">
                  <label htmlFor="selAdditive" className="calc__label">Which additive would you like to compare?</label>
                  <select name="selAdditive" onChange={additiveChange} id="selAdditive" className="calc__select">
                    {
                      products.map((product, i) => {
                        return (
                          <option
                            key={i}
                            hidden={product.display === 'hidden' ? 'hidden' : ''}
                            selected={product === additive ? 'selected' : ''}
                            value={product.id}>
                            {product.name}
                          </option>
                        )
                      })
                    }
                  </select>
                </div>
                <div className="calc">
                  <label htmlFor="selSpecies" className="calc__label">Which species?</label>
                  <select name="selSpecies" id="selSpecies" onChange={speciesChange} className="calc__select">
                    <option hidden='hidden' selected={species === 'empty' ? 'selected' : ''}>Select</option>

                    {
                      options.map((option, i) => {
                        return (
                          <option
                            key={i}
                            selected={option.id === species ? 'selected' : ''}
                            value={option.id}>
                            {option.name}
                          </option>
                        )
                      })
                    }

                  </select>
                </div>
                <div className={species === 'empty' ? 'calc hidden' : 'calc'}>
                  <label htmlFor="selPhase" className="calc__label">
                    Which production phase?
                  </label>
                  <select name="selPhase" oid="selPhase" onChange={phaseChange} className="calc__select">
                    <option default='default' hidden='hidden' value='empty'>Select</option>
                    <>
                      {
                        additiveStages[0].map((stage, i) => {
                          return (
                            <option value={stage.phase + ' ' + stage.dose}>{stage.name} - {stage.dose}g</option>
                          )
                        })
                      }
                    </>
                  </select>
                </div>
                <div className={!initialFeedingRate ? 'calc hidden' : 'calc'}>
                  <label htmlFor="inpDose" className="calc__label">Feeding Rate</label>
                  <input name="inpDose" type="number" step="0.01" onChange={feedingRateChange} id="inpDose" className="calc__input" value={feedingRate} />
                  <p className="calc__helper settings-on" onClick={openSettings}>Measurement Units</p>
                  <p className="hidden">Rate must be more than 0</p>
                  <p className="calc__helper">g / head / day</p>
                </div>
                <div className={!initialFeedingRate ? 'calc hidden' : 'calc'}>
                  <label htmlFor="inpFood" className="calc__label">Feed Intake</label>
                  <input name="inpFood" type="number" step="0.01" onChange={feedIntakeChange} id="inpFood" className="calc__input" value={feedIntake} />
                  <p className="hidden"></p><p className="hidden">Intake must be more than 0</p>
                  <p className="calc__helper">{measurement === 'imperial' ? 'lb' : 'kg'} / day (1 cup ≈ 0.25lb ≈ 0.113kg)</p>
                </div>
                <div className={!feedIntake || !feedingRate ? 'calc hidden' : 'calc'}>
                  <label htmlFor="inpPrice" className="calc__label">Additive Cost</label>
                  <input type="number" min="0.01" step="0.01" name="inpPrice" id="inpPrice" className="calc__input" value={
                    purchaseSource === 'direct' ? priceDirect : pricePartner
                  } />
                  <p className="calc__helper settings-on" onClick={openSettings}>Sources &amp; Market Prices</p>
                  <p className="calc__helper">USD / {measurement === 'imperial' ? 'lb' : 'kg'}</p></div>


                <button id="submit" type="button" className={!feedIntake || !feedingRate ? 'hidden' : ''} aria-hidden="false" onClick={openResults}>CALCULATE</button>
                <button id="reset" type="button" className="btn-right" aria-hidden="false" onClick={resetForm}>RESET</button>
              </div>

              <SettingsView
                products={products}
                additiveGroup={additiveGroup}
                additiveGroupPriceChange={additiveGroupPriceChange}
                measurement={measurement}
                measurementUnitChange={measurementUnitChange}
                purchaseSource={purchaseSource}
                purchaseSourceChange={purchaseSourceChange}
                settingsView={settingsView}
                closeSettings={closeSettings}
              />

              <div className={resultsView}>
                <aside className={additive.id === 'bzam'? '' : 'hidden'}>
                  <img src={logoAmaferm} className="logo-amaferm" alt="BioZyme Amaferm logo" />
                  <h5 className="pull">
                    Amaferm<sup>®</sup> is a precision-based prebiotic designed to enhance digestibility by amplifying nutrient supply for maximum performance.
                </h5>
                  <p>Amaferm, produced from a select strain of Aspergillus oryzae, was developed to stimulate resident microbiota and increase nutrient absorption.</p>
                  <div className="pull-ul">
                    <ul>
                      <li>Promotes feed <strong>INTAKE</strong></li>
                      <li>Increases feed <strong>DIGESTIBILITY</strong></li>
                      <li>Maximizes nutrient <strong>ABSORPTION</strong></li>
                    </ul>
                  </div>
                </aside>
                <div id="cardContainer">
                  {additiveGroup.map((product, index) => {

                    let weightPerTon = 0;
                    let pricePerTon = 0;
                    let pricePerHead = 0;

                    if (product.id === additive.id) {
                      weightPerTon = calculateWeightPerTon(measurement, feedingRate, feedIntake);
                    } else {
                      weightPerTon = calculateWeightPerTon(measurement, getProductRate(product.id), feedIntake)
                    }

                    if (purchaseSource === 'direct') {
                      pricePerTon = calculatePricePerTon(weightPerTon, product.price.direct);
                      if (product.id === additive.id) {
                        pricePerHead = calculatePricePerHead(measurement, product.price.direct, feedingRate);
                      } else {
                        pricePerHead = calculatePricePerHead(measurement, product.price.partner, getProductRate(product.id));
                      }

                    } else {
                      pricePerTon = calculatePricePerTon(weightPerTon, product.price.partner);
                      if (product.id === additive.id) {
                        pricePerHead = calculatePricePerHead(measurement, product.price.direct, feedingRate);
                      } else {
                        pricePerHead = calculatePricePerHead(measurement, product.price.partner, getProductRate(product.id));
                      }
                    }

                    return (
                      <div className={product.id === additive.id ? 'card card--pri' : 'card card--sec'}>

                        <h2 className="card__title">{product.name}</h2>

                        <p>
                          <span className="card__label">
                            {measurement === 'imperial' ? 'Additive lb / US ton feed' : 'Additive kg / Mt feed'}
                          </span>
                          <span className="card__number">{weightPerTon}</span>
                        </p>

                        <p>
                          <span className="card__label">
                            {measurement === 'imperial' ? 'USD / US ton feed' : 'USD / Mt feed'}
                          </span>
                          <span className="card__number">${pricePerTon}</span>
                        </p>

                        <p>
                          <span className="card__label">
                            USD / head / day
                          </span>
                          <span className="card__number">${pricePerHead}</span>
                        </p>

                        <p>
                          <span className="card__label">
                            {measurement === 'imperial' ? 'Price per lb' : 'Price per kg'}
                          </span>
                          <span className="card__number">${purchaseSource === 'direct' ? product.price.direct : product.price.partner}</span>
                        </p>

                      </div>
                    )
                  })}
                </div>
                <button id="btnResultsClose" type="button" className="btn-right" aria-hidden="false" onClick={closeResults}>Back</button>
              </div>

            </>
          </form>
        </>
        <Loader showState="hidden" />
      </main>
      <Footer />
    </>
  );
}

export default App;
