import React, { useState } from 'react';

import { firstUpper } from "../../utilities/strings";

const SettingsView = ({
    additive,
    additiveGroup,
    additiveGroupPriceChange,
    measurement,
    measurementUnitChange,
    purchaseSource,
    purchaseSourceChange,
    settingsView,
    closeSettings
}) => {

    return (
        <div className={settingsView}>
            <span id="btnSettingsClose" className="settings-off" onClick={closeSettings} aria-hidden="false"></span>
            <h1 className="title">Additive Inclusion Calculator Settings</h1>

            <div className="calc">
                <label htmlFor="selUnits" className="calc__label">Measurement Units</label>
                <select value={measurement} onChange={measurementUnitChange} name="selUnits" id="selUnits" className="calc__select">
                    {['imperial', 'metric'].map((option, i) => {
                        return (
                            <option value={option} key={i}>{firstUpper(option)}</option>
                        )
                    })}
                </select>
            </div>

            <div className="calc">
                <label htmlFor="selSource" className="calc__label">How do you intend to purchase?</label>
                <select value={purchaseSource} onChange={purchaseSourceChange} name="selSource" id="selSource" className="calc__select">
                    <option value="direct">Direct from Manufacturer</option>
                    <option value="partner">Through Partner (Delivered)</option>
                </select>
            </div>

            <div className="body">
                <h3>Manufacturer Direct Price</h3>
                {
                    additiveGroup.map((product) => {
                        return (
                            <div className="calc">
                                <label htmlFor={product.id + '-price'} className="calc__label">{product.name}</label>
                                <input name={purchaseSource === 'direct' ? product.id + '-direct' : product.id + '-partner'}
                                    id={purchaseSource === 'direct' ? product.id + '-direct' : product.id + '-partner'}
                                    key={purchaseSource === 'direct' ? product.id + '-direct' : product.id + '-partner'}
                                    className="calc__input"
                                    onChange={additiveGroupPriceChange}
                                    value={
                                        purchaseSource === 'direct' ? product.price.direct : product.price.partner
                                    } 
                                />
                                <p className="hidden"></p>
                                <p className="calc__helper">USD / {measurement === 'imperial' ? 'lb' : 'kg'}</p>
                            </div>
                        )
                    })
                }

            </div>
            <button id="btnSettingsBack" type="button" className="btn-right" aria-hidden="false" onClick={closeSettings}>Close Settings</button>
        </div>

    )
}


export default SettingsView;